<template>
  <CRow>
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader>
          Gebeurtenissen
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="timeline timed_moments v-application v-application--is-ltr">
                <v-timeline :align-top="true" :reverse="true">
                  <v-timeline-item v-for="moment in timedMoments" :key="moment.timed_moment_id" :color="getColor(moment.tm_type_id)" :icon="getIcon(moment.tm_type_id)" fill-dot>           
                    <v-card :color="getColor(moment.tm_type_id)">
                      <v-card-title class="label mb-0">
                        <v-row class="labsel">
                          <v-col cols="6" lg="6" class="pt-0 pb-0 text-left">
                            {{moment.label}}                            
                          </v-col>
                          <v-col cols="6" lg="6" class="pt-0 pb-0 text-right">
                            {{moment.date_created}}
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-container class="pt-0 pb-0">
                        <v-row class="description">
                          <v-col cols="8" lg="8" md="12" sm="12">                            
                            <p class="mb-0">{{moment.description}}</p>
                            <!-- <p class="mb-0">{{moment.date_created}}</p> -->
                          </v-col>
                          <v-col cols="4" lg="4" md="12" sm="12"> 
                            <v-row>
                              <v-col cols="6" lg="6" class="pt-0 pb-0">
                                <div class="overall_score text-center">
                                  <span class="integral">{{ getScoreIntegral(moment.current_overall_score) }}</span>
                                  <span class="decimal">.</span>
                                  <span class="fractional">{{ getScoreFracetional(moment.current_overall_score) }}</span>
                                </div>              
                              </v-col>
                              <v-col cols="6" lg="6" class="pt-0 pb-0">
                                <div class="score text-center" v-bind:class="{increased : moment.score_difference > 0, decreased : moment.score_difference < 0}">
                                  <i class="icon fas" v-bind:class="{'fa-arrow-circle-up': moment.score_difference > 0, 'fa-arrow-circle-down': moment.score_difference < 0}"/>
                                  <span class="growth ml-1">{{moment.score_difference}}</span>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </div>              
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>       
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'TimedMoments',
  components: {
    QuickEdit,    
  },
  data() {
    return {      
      timedMoments: []
    }
  },
  methods: {
    getTimedMoments () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/timed_moments')
      .then(res => {
        this.timedMoments = res.data.data;
                
        for(var index = 0; index < this.timedMoments.length; index++) {
          (index < this.timedMoments.length - 1) ? this.timedMoments[index].score_difference = parseFloat(this.timedMoments[index].current_overall_score - this.timedMoments[index+1].current_overall_score).toFixed(1) : this.timedMoments[index].score_difference = null;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getColor(type) {
      if(type === 1) {
        return 'var(--DashboardSecondary)';
      } else if(type === 2) {
        return 'var(--DashboardPrimary)';
      } else if(type === 3) {
        return 'var(--DashboardLightGreen)';
      } else if(type === 4) {
        return 'var(--DashboardDarkGreen)';
      }
    },
    getIcon(type) {
      if(type === 1 || type == 2) {
        return 'fas fa-trophy';
      } else if(type === 3 || type == 4) {
        return 'fas fa-user-plus';
      }           
    },
    getScoreIntegral(score) {
      return score.substring(0, score.indexOf('.'));
    },
    getScoreFracetional(score) {
      return score.substring(score.indexOf('.') + 1);
    }    
  },
  mounted: function(){
    this.getTimedMoments();
  }
}
</script>